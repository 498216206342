import { HarvestMoveStateModel } from '@store/mobile/harvest-move/harvest-move.model';

export class PatchHarvestMove {
  public static readonly type = '[Harvest Move] Harvest Move patched';

  constructor(public harvestMove: Partial<HarvestMoveStateModel>) {}
}

export class ResetHarvestMove {
  public static readonly type = '[Harvest Move] Reset Harvest Move';
}
