import { Action, State, StateContext } from '@ngxs/store';
import { PatchHarvestMove, ResetHarvestMove } from '@store/mobile/harvest-move/harvest-move.actions';
import { HarvestMoveStateModel } from '@store/mobile/harvest-move/harvest-move.model';
import { Injectable } from '@angular/core';

@State<HarvestMoveStateModel>({
  name: 'harvestMove',
  defaults: {
    origin: null,
    destination: null,
    harvests: [],
    selected: [],
  },
})
@Injectable()
export class HarvestMoveState {
  @Action(PatchHarvestMove)
  public patch(ctx: StateContext<Partial<HarvestMoveStateModel>>, { harvestMove }: PatchHarvestMove): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      ...harvestMove,
    });
  }

  @Action(ResetHarvestMove)
  public reset(ctx: StateContext<Partial<HarvestMoveStateModel>>): void {
    ctx.setState({});
  }
}
